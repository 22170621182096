const feedMeta = () => ({
  title: `Feed | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        "Your feed tracks the climbers you follow and the boulders and routes they send.",
    },
    {
      name: "og:title",
      content: "Feed | Pebble Climbing",
    },
    {
      name: "og:description",
      content:
        "Your feed tracks the climbers you follow and the boulders and routes they send.",
    },
  ],
})

const areaMeta = ({ name, description, image }) => ({
  title: `${name || "Area"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: description || "An area in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name || "Area"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: description || "An area in the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image ?? "https://www.pebbleclimbing.com/pebble-og-image.png",
    },
  ],
})

const editAreaMeta = () => ({
  title: `Edit Area | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this area in the Pebble Climbing Guide",
    },
    {
      name: "og:title",
      content: "Edit Area | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit this area in the Pebble Climbing Guide",
    },
  ],
})

const editGuideMeta = () => ({
  title: `Edit Guide | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this guide in the Pebble Climbing Guide",
    },
    {
      name: "og:title",
      content: "Edit Guide | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit this guide in the Pebble Climbing Guide",
    },
  ],
})

const editRouteMeta = (name) => ({
  title: `${name ? `Edit ${name}` : "Create a route"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this route in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name ? `Edit ${name}` : "Create a route"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Edit this route in the Pebble Climbing Guides",
    },
  ],
})

const editProblemMeta = (name) => ({
  title: `${name ? `Edit ${name}` : "Create a problem"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this boulder in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${
        name ? `Edit ${name}` : "Create a problem"
      } | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Edit this boulder in the Pebble Climbing Guides",
    },
  ],
})

const guideMeta = ({ name, description, image }) => ({
  title: `${name || "Guide"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        (description || "A climbing guide in the Pebble Climbing Guides") +
        " Bouldering, and route climbing guides.",
    },
    {
      name: "og:title",
      content: `${name || "Guide"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content:
        (description || "A climbing guide in the Pebble Climbing Guides") +
        " Bouldering, and route climbing guides.",
    },
    {
      name: "og:image",
      content: image ?? "https://www.pebbleclimbing.com/pebble-og-image.png",
    },
  ],
})

const guidesMeta = () => ({
  title: `Guides | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Search for bouldering or route climbing guides.",
    },
    {
      name: "og:title",
      content: `Guides | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Search for bouldering or route climbing guides.",
    },
  ],
})

const problemMeta = ({ name, description, image }) => ({
  title: `${name || "Boulder"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: description || "A boulder in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name || "Boulder"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: description || "A boulder in the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image ?? "https://www.pebbleclimbing.com/pebble-og-image.png",
    },
  ],
})

const routeMeta = ({ name, description, image }) => ({
  title: `${name || "Route"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: description || "A route in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name || "Route"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: description || "A route in the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image ?? "https://www.pebbleclimbing.com/pebble-og-image.png",
    },
  ],
})

const mediaMeta = ({ name, description, image }) => ({
  title: `${name || "Media"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: description || "Media from the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name || "Media"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: description || "Media from the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image,
    },
  ],
})

const editMediaMeta = () => ({
  title: `Edit Media | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this media in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Edit Media | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit this media in the Pebble Climbing Guides",
    },
  ],
})

const authMediaMeta = () => ({
  title: `Authorization External Media Source | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Authorization External Media Source for Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Authorization External Media Source  | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Authorization External Media Source for Pebble Climbing Guides",
    },
  ],
})

const createSendMeta = () => ({
  title: `Log a Send | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Log a send in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Log a Send | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Log a send in the Pebble Climbing Guides",
    },
  ],
})

const editSendProblemMeta = () => ({
  title: `Edit Your Send | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit your send in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Edit Your Send | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit your send in the Pebble Climbing Guides",
    },
  ],
})

const editSendRouteMeta = () => ({
  title: `Edit Your Send | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit your send in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Edit Your Send | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit your send in the Pebble Climbing Guides",
    },
  ],
})

const sendMeta = ({ name }) => ({
  title: `${name || "Send"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: `${name} from the Pebble Climbing Guides`,
    },
    {
      name: "og:title",
      content: `${name || "Send"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: `${name} from the Pebble Climbing Guides`,
    },
  ],
})

const athletesMeta = () => ({
  title: `Athletes | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        "Search for athletes using the Pebble Climbing Guides and follow their activity.",
    },
    {
      name: "og:title",
      content: "Athletes | Pebble Climbing",
    },
    {
      name: "og:description",
      content:
        "Search for athletes using the Pebble Climbing Guides and follow their activity.",
    },
  ],
})

const editUserMeta = () => ({
  title: `Edit Your Profile | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit your profile on Pebble Climbing.",
    },
    {
      name: "og:title",
      content: "Edit Your Profile | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit your profile on Pebble Climbing.",
    },
  ],
})

const forgotPasswordMeta = () => ({
  title: `Forgot Your Password | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Enter your email to reset your password on Pebble Climbing.",
    },
    {
      name: "og:title",
      content: "Forgot Your Password | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Enter your email to reset your password on Pebble Climbing.",
    },
  ],
})

const loginOrRegisterMeta = () => ({
  title: `Login or Register | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        "Join Pebble Climbing. Track your sends, follow your friends, and plan your next climbing trip.",
    },
    {
      name: "og:title",
      content: "Login or Register | Pebble Climbing",
    },
    {
      name: "og:description",
      content:
        "Join Pebble Climbing. Track your sends, follow your friends, and plan your next climbing trip.",
    },
  ],
})

const resetPasswordMeta = () => ({
  title: `Reset Your Password | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Use this form to reset your password on Pebble Climbing.",
    },
    {
      name: "og:title",
      content: "Reset Your Password | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Use this form to reset your password on Pebble Climbing.",
    },
  ],
})

const userMeta = ({ name, picture }) => ({
  title: `${name || "User"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: `${name}‘s profile on Pebble Climbing`,
    },
    {
      name: "og:title",
      content: `${name || "User"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: `${name}‘s profile on Pebble Climbing`,
    },
    {
      name: "og:image",
      content: picture,
    },
  ],
})

const feedItemMeta = ({ title }) => ({
  title: `${title} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: `${title} on Pebble Climbing`,
    },
    {
      name: "og:title",
      content: `${title} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: `${title} on Pebble Climbing`,
    },
  ],
})

const editGymMeta = () => ({
  title: `Edit Gym | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this gym in Pebble Climbing",
    },
    {
      name: "og:title",
      content: "Edit Gym | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit this gym in Pebble Climbing",
    },
  ],
})

const editGymPermissionsMeta = () => ({
  title: `Gym Permissions | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit your gym’s permissions",
    },
    {
      name: "og:title",
      content: "Gym Permissions | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit your gym’s permissions",
    },
  ],
})

const settingSchedulerMeta = () => ({
  title: "Setting Scheduler | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Create the Setting Scheduler",
    },
    {
      name: "og:title",
      content: "Setting Scheduler | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Create the Setting Scheduler",
    },
  ],
})

const settingScheduleWidgetMeta = () => ({
  title: "Setting Schedule | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Setting schedule",
    },
    {
      name: "og:title",
      content: "Setting Schedule | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Setting schedule",
    },
  ],
})

const monthlySetterReportMeta = () => ({
  title: "Monthly Setter Report | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "View the Monthly Setter Report",
    },
    {
      name: "og:title",
      content: "Monthly Setter Report | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "View the Monthly Setter Report",
    },
  ],
})

const settingDistributionMeta = () => ({
  title: "Setting Distribution | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Create the Setting Distribution",
    },
    {
      name: "og:title",
      content: "Setting Distribution | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Create the Setting Distribution",
    },
  ],
})

const editGymAreaMeta = () => ({
  title: `Edit an Area of the Gym | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit this area in your gym",
    },
    {
      name: "og:title",
      content: "Edit an Area of the Gym | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit this area in your gym",
    },
  ],
})

const editOverviewMapMeta = () => ({
  title: `Edit overview maps of the Gym | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Edit overview maps in your gym",
    },
    {
      name: "og:title",
      content: "Edit an overview map of the Gym | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit overview maps in your gym",
    },
  ],
})

const createOverviewMapMeta = () => ({
  title: `Create overview maps of the Gym | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Create overview maps in your gym",
    },
    {
      name: "og:title",
      content: "Create an overview map of the Gym | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Create overview maps in your gym",
    },
  ],
})

const overviewMapsMeta = () => ({
  title: `Overview maps of the Gym | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Overview maps of the gym",
    },
    {
      name: "og:title",
      content: "Overview maps of the Gym | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Overview maps of the gym",
    },
  ],
})

const gymMeta = ({ name, description, image }) => ({
  title: `${name || "Gym"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: description || "A climbing gym in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name || "Gym"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: description || "A climbing gym in the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image,
    },
  ],
})

const setterDocsMeta = () => ({
  title: `Route Setting Docs | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        "Instructions for how to manage a gym and set routes on Pebble Climbing",
    },
    {
      name: "og:title",
      content: `Route Setting Docs | Pebble Climbing`,
    },
    {
      name: "og:description",
      content:
        "Instructions for how to manage a gym and set routes on Pebble Climbing",
    },
  ],
})

const gymsMeta = () => ({
  title: `Climbing Gyms | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Climbing gyms in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `Climbing Gyms | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Climbing gyms in the Pebble Climbing Guides",
    },
  ],
})

const gymAreaMeta = ({ name, description, image }) => ({
  title: `${name || "An Area of the Gym"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        description || "An area in a gym listed in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: `${name || "An Area of the Gym"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content:
        description || "An area in a gym listed in the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image,
    },
  ],
})

const termsOfUse = () => ({
  title: `Terms of Use | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Terms of use for Pebble Climbing",
    },
    {
      name: "og:title",
      content: `Terms of Use | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Terms of use for Pebble Climbing",
    },
  ],
})

const privacyPolicy = () => ({
  title: `Privacy Policy | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Privacy Policy for Pebble Climbing",
    },
    {
      name: "og:title",
      content: `Privacy Policy | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Privacy Policy for Pebble Climbing",
    },
  ],
})

const instagramDataDeletionStatus = () => ({
  title: `Instagram Data Deletion Status | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Check status of data deletion request from Instagram",
    },
    {
      name: "og:title",
      content: `Instagram Data Deletion Status | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Check status of data deletion request from Instagram",
    },
  ],
})

const compMeta = ({ name, description, image }) => ({
  title: `${name || "Competition"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: description || "A climbing Competition on Pebble",
    },
    {
      name: "og:title",
      content: `${name || "Competition"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: description || "An area in the Pebble Climbing Guides",
    },
    {
      name: "og:image",
      content: image ?? "https://www.pebbleclimbing.com/pebble-og-image.png",
    },
  ],
})

const editCompMeta = () => ({
  title: `Edit a Competition | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "A climbing competition on Pebble",
    },
    {
      name: "og:title",
      content: `Edit a competition | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "A climbing competition on Pebble",
    },
  ],
})

const manageCompetitorsMeta = () => ({
  title: `Manage Competitors | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content: "Manage competitors for a competition on Pebble",
    },
    {
      name: "og:title",
      content: `Manage Competitors | Pebble Climbing`,
    },
    {
      name: "og:description",
      content: "Manage competitors for a competition on Pebble",
    },
  ],
})

const editCompSetMeta = () => ({
  title: "Edit Comp Round | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Edit a climbing comp round in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Edit Comp Round | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit a climbing comp round in the Pebble Climbing Guides",
    },
  ],
})

const editCompetitorBonusMeta = () => ({
  title: "Edit Competitor Bonus | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Edit a climbing comp round in the Pebble Climbing Guides",
    },
    {
      name: "og:title",
      content: "Edit Comp Round | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit a climbing comp round in the Pebble Climbing Guides",
    },
  ],
})

const editCompScoreMeta = () => ({
  title: "CompScore | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Log a score for a competition",
    },
    {
      name: "og:title",
      content: "CompScore | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Log a score for a competition",
    },
  ],
})

const gymSubscriptionMeta = () => ({
  title: "Sign up your gym | Pebble Climbing",
  meta: [
    {
      name: "description",
      content:
        "Manage your route setting and give your members the ability to log their climbing on Pebble.",
    },
    {
      name: "og:title",
      content: "Sign up your gym",
    },
    {
      name: "og:description",
      content:
        "Manage your route setting and give your members the ability to log their climbing on Pebble.",
    },
  ],
})

const billingSettingsMeta = () => ({
  title: "Update your billing | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Update your billing settings",
    },
    {
      name: "og:title",
      content: "Update your billing",
    },
    {
      name: "og:description",
      content: "Update your billing settings",
    },
  ],
})

const gymOnboardingMeta = () => ({
  title: "Welcome to Pebble | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Thanks for adding your gym to the Pebble ecosystem.",
    },
    {
      name: "og:title",
      content: "Welcome to Pebble",
    },
    {
      name: "og:description",
      content: "Thanks for adding your gym to the Pebble ecosystem.",
    },
  ],
})

const scannersMeta = () => ({
  title: "Scanners | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Your gym scanners",
    },
    {
      name: "og:title",
      content: "Scanners | Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Your gym scanners",
    },
  ],
})

const editSurveyMeta = () => ({
  title: "Edit Your Survey | Pebble Climbing",
  meta: [
    {
      name: "description",
      content: "Edit your survey",
    },
    {
      name: "og:title",
      content: "Edit Your Survey  Pebble Climbing",
    },
    {
      name: "og:description",
      content: "Edit your survey",
    },
  ],
})

const surveyMeta = ({ name, description, image }) => ({
  title: `${name || "Survey"} | Pebble Climbing`,
  meta: [
    {
      name: "description",
      content:
        description || "A survey on Pebble that will help improve your gym.",
    },
    {
      name: "og:title",
      content: `${name || "Survey"} | Pebble Climbing`,
    },
    {
      name: "og:description",
      content:
        description || "A survey on Pebble that will help improve your gym.",
    },
    {
      name: "og:image",
      content: image ?? "https://www.pebbleclimbing.com/pebble-og-image.png",
    },
  ],
})

export {
  feedMeta,
  areaMeta,
  editAreaMeta,
  editGuideMeta,
  editRouteMeta,
  editProblemMeta,
  guideMeta,
  guidesMeta,
  problemMeta,
  routeMeta,
  mediaMeta,
  editMediaMeta,
  authMediaMeta,
  createSendMeta,
  editSendProblemMeta,
  editSendRouteMeta,
  sendMeta,
  athletesMeta,
  editUserMeta,
  forgotPasswordMeta,
  loginOrRegisterMeta,
  resetPasswordMeta,
  userMeta,
  feedItemMeta,
  editGymMeta,
  editGymAreaMeta,
  editOverviewMapMeta,
  createOverviewMapMeta,
  overviewMapsMeta,
  gymMeta,
  setterDocsMeta,
  gymsMeta,
  settingSchedulerMeta,
  settingScheduleWidgetMeta,
  settingDistributionMeta,
  gymAreaMeta,
  editGymPermissionsMeta,
  termsOfUse,
  privacyPolicy,
  instagramDataDeletionStatus,
  compMeta,
  editCompMeta,
  editCompScoreMeta,
  manageCompetitorsMeta,
  editCompSetMeta,
  editCompetitorBonusMeta,
  gymSubscriptionMeta,
  billingSettingsMeta,
  gymOnboardingMeta,
  monthlySetterReportMeta,
  scannersMeta,
  editSurveyMeta,
  surveyMeta,
}
